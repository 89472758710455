import { RouteInfo } from "./sidebar.metadata";


export const AGENT_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/agent-dashboard',
        title: 'Home',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },  
   {
    path: '',
    title: 'Leads',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/agent-lead',
        title: 'Agent Lead',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }
 
];
