import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ToastrModule } from 'ngx-toastr';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { SuperAdminLayoutComponent } from './layout/super-admin-layout/super-admin-layout.component';
import { SubAdminLayoutComponent } from './layout/sub-admin-layout/sub-admin-layout.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { EmployeeLayoutComponent } from './layout/employee-layout/employee-layout.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { NumberDirective } from './service/numbers-only.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgOtpInputModule } from  'ng-otp-input';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    SuperAdminLayoutComponent,
    SubAdminLayoutComponent,
    AdminLayoutComponent,
    EmployeeLayoutComponent,   
    BlankLayoutComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    FullLayoutComponent,
    NumberDirective,
    ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
	  ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule,
    Ng2SearchPipeModule,
    RouterModule.forRoot(Approutes),
    NgMultiSelectDropDownModule.forRoot(),
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBUb3jDWJQ28vDJhuQZxkC0NXr_zycm8D0' }),
    NgOtpInputModule
  ],
  providers: [AuthService, AuthGuard,  DatePipe    ,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor,  multi: true},
    {      
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },      
    // {provide: LocationStrategy, useClass: HashLocationStrategy}    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
