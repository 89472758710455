import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../src/app/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ADMIN_ROUTES } from './admin-menu-items';
import { SUB_ADMIN_ROUTES } from './sub-admin-menu-items';
import { USER_ROUTES } from './user-menu-items';
import { EMPLOYEE_ROUTES } from './employee-menu-items';
import { TELECALLER_ROUTES } from './telecaller-menu-items';
import { BACKEND_MANAGER_ROUTES } from './backend-manager-menu-items';
import { AGENT_ROUTES } from './agent-menu-items';
import { SALESPERSON_ROUTES } from './salesperson-menu-items';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  // End open close
  ngOnInit() {
    //console.log(" this.authService.getLoggedInUserRole() :: " + this.authService.getLoggedInUserRole());
    if(this.authService.getLoggedInUserRole() == "ROLE_TELECALLER"){
      this.sidebarnavItems = TELECALLER_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else if(this.authService.getLoggedInUserRole() == "ROLE_ADMIN"){
      this.sidebarnavItems = ADMIN_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else if(this.authService.getLoggedInUserRole() == "ROLE_SUB_ADMIN"){
      this.sidebarnavItems = SUB_ADMIN_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else if(this.authService.getLoggedInUserRole() == "ROLE_USER"){
      this.sidebarnavItems = USER_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else if(this.authService.getLoggedInUserRole() == "ROLE_EMPLOYEE"){
      this.sidebarnavItems = EMPLOYEE_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }else if(this.authService.getLoggedInUserRole() == "ROLE_AGENT"){
      this.sidebarnavItems = AGENT_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }if(this.authService.getLoggedInUserRole() == "ROLE_BACKEND_MANAGER"){
      this.sidebarnavItems = BACKEND_MANAGER_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }if(this.authService.getLoggedInUserRole() == "ROLE_EXECUTIVE_PERSON"){
      this.sidebarnavItems = SALESPERSON_ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    
  }
}
