import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },

  {
    path: '',
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./subscriber-management/subscriber.module').then(m => m.SubscriberModule)
  },

  {
    path: '',
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./service-management/service.module').then(m => m.ServiceModule)
  },

  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./ultimatum/ultimatum.module').then(m => m.UltimatumModule)
  },

  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./renewal/renewal.module').then(m => m.RenewalModule)
  },

  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./unsubscription/unsubscription.module').then(m => m.UnsubscriptionModule)
  },

  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./service-access/service-access.module').then(m => m.ServiceAccessModule)
  },


  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./notification/notification-log.module').then(m => m.NotificationLogModule)
  },


  {
    path: '', 
    component: FullLayoutComponent, canActivate: [AuthGuard],
    loadChildren: () => import('./user-management/user.module').then(m => m.UserModule)
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: '', redirectTo: '/payment/subscriber-payment', pathMatch: 'full' },
      {
        path: 'payment',
        loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      {
        path: 'authentication',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/login'
  }

];



