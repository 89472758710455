import { RouteInfo } from "./sidebar.metadata";


export const SALESPERSON_ROUTES: RouteInfo[] = [
  {
    path: '/executive-dashboard',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
   
  {
    path: '/executive-meeting',
    title: 'Executive meeting',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }
  

];
