import { Component, OnInit, HostListener } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
  constructor(public router: Router, public auth: AuthService) {}
  todayDate = new Date();
  logoData : any;
  isDefaultLogo : boolean = true; 

  loggedInUserName : string = "User";
  
  ngOnInit() {
    if(this.auth.getLoggedInUserName() != null && this.auth.getLoggedInUserName() != ''){
      this.loggedInUserName = this.auth.getLoggedInUserName();
      }else{
       this.loggedInUserName= "User";
      }

     if (this.router.url === '/') {
      if(this.auth.getLoggedInUserRole() == "ROLE_SUPER_ADMIN"){
        this.router.navigate(['/super-admin-dashboard']);
      }else if(this.auth.getLoggedInUserRole() == "ROLE_ADMIN"){
        this.router.navigate(['/admin-dashboard']);
      }else if(this.auth.getLoggedInUserRole() == "ROLE_SUB_ADMIN"){
        this.router.navigate(['/sub-admin-dashboard']);
      }else if(this.auth.getLoggedInUserRole() == "ROLE_USER"){
        this.router.navigate(['/user-dashboard']);
      }else if(this.auth.getLoggedInUserRole() == "ROLE_EMPLOYEE"){
        this.router.navigate(['/employee-dashboard']);
      }
   
  }
  this.defaultSidebar = this.options.sidebartype;
  this.handleSidebar();
  }

  tabStatus = 'justified';
  public isCollapsed = false;
  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar 
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

 

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
}


