import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private router: Router) {
    }

    sendToken(accessToken: string) {
        localStorage.setItem('userToken', accessToken);
    }

    getToken() {
        return localStorage.getItem('userToken');
    }
    isLoggednIn() {
        return this.getToken() !== null;
    }
    logout() {
        localStorage.clear();
        this.router.navigate(['/authentication/login']);
    }


    setLoggedInUserRole(loggedInUserRole: string) {
        localStorage.setItem('loggedInUserRole', loggedInUserRole);
    }

    getLoggedInUserRole() {
        return localStorage.getItem('loggedInUserRole');
    }


    setLoggedInUserId(loggedInUserId: string) {
        localStorage.setItem('loggedInUserId', loggedInUserId);
    }

    getLoggedInUserId() {
        return localStorage.getItem('loggedInUserId');
    }

    setLoggedInUserName(loggedInUserName: string) {
        localStorage.setItem('loggedInUserName', loggedInUserName);
    }

    getLoggedInUserName() {
        return localStorage.getItem('loggedInUserName');
    }



    setFilterStartDate(filter: any) {
        localStorage.setItem('startDate', filter);
    }

    getFilterStartDate() {
        return localStorage.getItem('startDate');
    }

    setFilterEndDate(filter: any) {
        localStorage.setItem('endDate', filter);
    }

    getFilterEndDate() {
        return localStorage.getItem('endDate');
    }


    setFilterSource(filter: string) {
        localStorage.setItem('source', filter);
    }

    getFilterSource() {
        return localStorage.getItem('source');
    }


    setFilterStatus(filter: string) {
        localStorage.setItem('status', filter);
    }

    getFilterStatus() {
        return localStorage.getItem('status');
    }


    setFilterLoanRequestStatus(filter: string) {
        localStorage.setItem('loanRequestStatus', filter);
    }

    getFilterLoanRequestStatus() {
        return localStorage.getItem('loanRequestStatus');
    }


    setFilterMeetingStatus(filter: string) {
        localStorage.setItem('meetingStatus', filter);
    }

    getFilterMeetingStatus() {
        return localStorage.getItem('meetingStatus');
    }



    setFilterLoanFileStatus(filter: string) {
        localStorage.setItem('loanFileStatus', filter);
    }

    getFilterLoanFileStatus() {
        return localStorage.getItem('loanFileStatus');
    }




    setReportRequestDateBy(filter: any) {
        localStorage.setItem('reportRequestDateBy', filter);
    }

    getReportRequestDateBy() {
        return localStorage.getItem('reportRequestDateBy');
    }


    setReportRequestDateValue(filter: any) {
        localStorage.setItem('reportRequestDateValue', filter);
    }

    getReportRequestDateValue() {
        return localStorage.getItem('reportRequestDateValue');
    }







    setFilterLoanCategoryStatus(filter: string) {
        localStorage.setItem('loanCategoryStatus', filter);
    }

    getFilterLoanCategoryStatus() {
        return localStorage.getItem('loanCategoryStatus');
    }
}
