import { RouteInfo } from "./sidebar.metadata";


export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/admin-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },  
 
  {
    path: '/service-management',
    title: 'Service',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 
  {
    path: '/subscriber-management',
    title: 'Subscriber',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 
  {
    path: '/renewal-details',
    title: 'Renewal',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 
  {
    path: '/ultimatum-details',
    title: 'Ultimatum',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },  {
    path: '/unsubscription-details',
    title: 'Unsubscription',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 

  {
    path: '/service-access-logs',
    title: 'Service Access Logs',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 

  {
    path: '/notification-logs',
    title: 'Notification Logs',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 
  // {
  //   path: '/user-management',
  //   title: 'User accounts',
  //   icon: 'mdi mdi-adjust',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }

];
