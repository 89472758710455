import { RouteInfo } from "./sidebar.metadata";


export const USER_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/user-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },  
   {
    path: '/bulk-lead',
    title: 'Bulk-Lead-Data',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },  
  {
   path: '/user-lead',
   title: 'User-Lead-Data',
   icon: 'mdi mdi-view-dashboard',
   class: '',
   extralink: false,
   submenu: []
 }
 
];