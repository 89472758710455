import { RouteInfo } from "./sidebar.metadata";


export const TELECALLER_ROUTES: RouteInfo[] = [

  {
    path: '/telecaller-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: [ ]
  },   
   {
    path: '/telecaller-lead',
    title: 'Lead',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: [   ]
  },   
   {
    path: '/loan-request',
    title: 'Inprocess Lead',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  }, 
  {
    path: '/loan-file-status',
    title: 'Loan File Status',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },  
  
 
];
