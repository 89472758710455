import { RouteInfo } from "./sidebar.metadata";


export const BACKEND_MANAGER_ROUTES: RouteInfo[] = [
  {
    path: '/manager-dashboard',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/manager-dashboard',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },   
   {
    path: '/loan-file',
    title: 'Loan Files',
    icon: 'mdi mdi-view-dashboard',
    class: '',
    extralink: false,
    submenu: [ ]
  } ,
 
];
