import { RouteInfo } from "./sidebar.metadata";


export const SUB_ADMIN_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Home',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/dashboard/home',
        title: 'Home',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Organization Management',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/apps/brand-management/info',
        title: 'Brand Info',
        icon: 'mdi mdi-email',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/brand-management/pricelist',
        title: 'Brand PriceList',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/brand-management/package',
        title: 'Brand Package',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/brand-management/charges',
        title: 'Brand Charges',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      },
    
      {
        path: '/apps/brand-management/discount',
        title: 'Brand Discount',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/apps/brand-management/brand-login',
        title: 'Brand Login',
        icon: 'mdi mdi-comment-processing-outline',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }, 
  {
    path: '',
    title: 'WareHouse Management',
    icon: 'mdi mdi-view-dashboard',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/apps/warehouse-management/warehouse',
        title: 'WareHouse',
        icon: 'mdi mdi-adjust',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  }
 
];
